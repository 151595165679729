import React from 'react';
import Grid, { GridProps } from '@mui/material/Grid';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import { WithButtonProps, GridDivider, Action } from '..';
import Field, { FieldProps } from './Field';

export type FormType = React.FormEvent<HTMLFormElement>;
export interface FormProps extends GridProps, FormCorProps {
    onFormSubmit: (e: FormType) => void;
    reset?: () => void;
    title?: string;
    disableGrid?: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    TitleProps?: TypographyProps;
    sxContainer?: SxProps<Theme>;
}

const Form: React.FC<FormProps> = React.memo(
    ({
        onFormSubmit,
        reset,
        TitleProps,
        title,
        disableGrid,
        maxWidth,
        sxContainer,
        children,
        ...props
    }) => {
        const submit = React.useCallback(
            (e: FormType) => {
                e.preventDefault();
                onFormSubmit(e);
                reset && reset();
            },
            [onFormSubmit, reset]
        );
        return (
            <Container
                component='form'
                maxWidth={maxWidth ? maxWidth : 'md'}
                onSubmit={submit}
                sx={{ m: 0, ...sxContainer }}
                disableGutters
            >
                {title && (
                    <Typography
                        variant='h2'
                        fontWeight={600}
                        mb={3}
                        textAlign='center'
                        {...TitleProps}
                    >
                        {title}
                    </Typography>
                )}
                {disableGrid ? (
                    <FormCore {...props} />
                ) : (
                    <Grid
                        container
                        spacing={props.spacing ? props.spacing : 3}
                        {...props}
                    >
                        <FormCore {...props} />
                    </Grid>
                )}
            </Container>
        );
    }
);

interface FormCorProps {
    label?: string;
    size?: 'small' | 'medium';
    loading?: boolean;
    disableAction?: boolean;
    ActionProps?: WithButtonProps;
    FieldProps?: FieldProps;
    Fields?: Array<FieldProps | 'divider'>;
}

function FormCore({
    Fields,
    size,
    FieldProps,
    ActionProps,
    disableAction,
    label,
    loading,
    children
}: WithChildren<FormCorProps>) {
    return (
        <>
            {Fields?.map((field, index) =>
                field === 'divider' ? (
                    <GridDivider />
                ) : (
                    <Field key={index} size={size} {...FieldProps} {...field} />
                )
            )}
            {children}
            {!disableAction && (
                <Action
                    av='Button'
                    grid
                    type='submit'
                    size={size}
                    loading={loading}
                    {...ActionProps}
                    label={label ?? 'submit'}
                />
            )}
        </>
    );
}

export default React.memo(Form);
