import React from 'react';
import { Form } from '..';
import { IContributor } from '../../context';
import { useFetch, useForm } from '../../hooks';

function AddContributor(): React.ReactElement {
    const [post, loading] = useFetch('/sponsors/contributor/add');
    const [details, onChange, reset] = useForm<IContributor>({
        FullName: '',
        Link: null,
        Amount: 0
    });

    const addContributor = React.useCallback(
        async () => await post({ data: details }, 'Added Sponsor'),
        [details, post]
    );

    return (
        <Form
            onFormSubmit={addContributor}
            reset={reset}
            label='Add Sponsor'
            Fields={[
                { name: 'FullName', onChange: onChange('FullName') },
                { name: 'Link', onChange: onChange('Link') },
                { name: 'Amount', onChange: onChange('Amount') }
            ]}
            loading={loading}
        />
    );
}

export default React.memo(AddContributor);
