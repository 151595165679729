import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AdminRoute, NotFoundRoute } from './Routes';
import { navigator } from './navigator.Config';
import { useFetch, useNavigate } from '../hooks';
import useAuth from '../context/useAuth';
import type_includes from '../functions/type_includes';
import { adminRoutes } from '../config/routes.config';

function Navigator(): React.ReactElement {
    const { path, navigate } = useNavigate();
    const { auth, checkSession, logout } = useAuth();
    const [post] = useFetch('/users/checkSession');
    const memoizedNavigator = React.useMemo(() => navigator, []);
    React.useEffect(() => {
        const utCheckSession = async () => {
            const response = await post({
                data: {
                    MemberGuid: auth.MemberGuid,
                    SessionGuid: auth.SessionGuid
                }
            });
            response.result === 'success'
                ? checkSession(response.data, 'session')
                : logout();
        };
        auth.SessionGuid
            ? utCheckSession()
            : !(type_includes(adminRoutes, path) || path !== '/login') &&
              navigate('/login');
    }, [
        auth.MemberGuid,
        auth.SessionGuid,
        checkSession,
        logout,
        navigate,
        path,
        post
    ]);
    return (
        <Switch>
            {memoizedNavigator.public.map(route => (
                <Route
                    key={route.path}
                    path={route.path}
                    component={route.component}
                    exact
                />
            ))}
            {memoizedNavigator.admin.map(route => (
                <AdminRoute key={route.path} path={route.path} exact>
                    <route.component />
                </AdminRoute>
            ))}
            <NotFoundRoute />
        </Switch>
    );
}
export default React.memo(Navigator);
