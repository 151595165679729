import React from 'react';
import Typography from '@mui/material/Typography';
import { useFetch, useForm } from '../../hooks';
import { Form } from '..';

function DonateOther(): React.ReactElement {
    const [post, loading] = useFetch('/contact/donate');
    const [donateInfo, onChange] = useForm({
        FullName: 'anonymous',
        Email: '',
        Phone: '',
        Message: ''
    });
    const submit = React.useCallback(
        async () => await post({ data: donateInfo }, 'Message Sent!'),
        [donateInfo, post]
    );
    return (
        <>
            <Form
                onFormSubmit={submit}
                loading={loading}
                label='Donate'
                Fields={[
                    {
                        label: 'Name',
                        onChange: onChange('FullName'),
                        optional: true,
                        endAdornment: (
                            <Typography variant='caption' component='legend'>
                                optional
                            </Typography>
                        )
                    },
                    { label: 'Email', onChange: onChange('Email'), sm: 6 },
                    {
                        label: 'Phone',
                        onChange: onChange('Phone'),
                        optional: true,
                        endAdornment: (
                            <Typography variant='caption' component='legend'>
                                optional
                            </Typography>
                        ),
                        sm: 6
                    },
                    {
                        label: 'Message',
                        onChange: onChange('Message'),
                        multiline: true
                    }
                ]}
            />
        </>
    );
}

export default React.memo(DonateOther);
