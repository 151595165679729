import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import { Facebook, Instagram, Mail } from '@mui/icons-material';
import { Info } from '../../constants';
import { Action } from '..';
import { ContactForm, FooterColumn } from '.';

function Footer(props: BoxProps) {
    const memoizedInfo = React.useMemo(() => Info, []);
    return (
        <Box width='100vw' bgcolor='#d0d0d0' id='contact' {...props}>
            <Container sx={{ py: 2 }} maxWidth='lg'>
                <Stack
                    direction={{ xs: 'column', md: 'row' }}
                    justifyContent='center'
                    alignItems={{ xs: 'center', md: 'flex-start' }}
                    spacing={6}
                    width='100%'
                    py={3}
                >
                    <ContactForm />
                    <Stack direction='row' spacing={{ xs: 3, sm: 6 }}>
                        <FooterColumn
                            title='Documents'
                            items={[
                                {
                                    href: '/pdfs/press-release.pdf',
                                    value: 'Press Release'
                                },
                                {
                                    href: '/pdfs/proposal.pdf',
                                    value: 'Proposal'
                                },
                                {
                                    href: '/pdfs/sponsorship.pdf',
                                    value: 'Sponsorship'
                                },
                                {
                                    href: '/pdfs/wishlist.pdf',
                                    value: 'Wishlist'
                                },
                                {
                                    href: '/images/NPO.jpeg',
                                    value: 'NPO'
                                }
                            ]}
                        />
                        <FooterColumn
                            title='Pages'
                            items={[
                                { to: '/', value: 'Home' },
                                // { to: '/blogs', value: 'Blog' },
                                { to: '/why-we-walk', value: 'Why we walk' },
                                { to: '/donate', value: 'Donate' },
                                { to: '/login', value: 'Admin Login' }
                            ]}
                        />
                        <FooterColumn
                            title='Details'
                            items={[
                                {
                                    href: 'https://dwfc.org.za',
                                    value: 'Dynamic Women for Children'
                                },
                                {
                                    href: 'https://youtu.be/RittB1GTyQ0',
                                    value: 'Project launch interview'
                                },
                                {
                                    value: memoizedInfo.RegistrationDetails
                                        .Article
                                },
                                {
                                    label: 'NPC',
                                    value: memoizedInfo.RegistrationDetails.NPC
                                },
                                {
                                    label: 'NPO',
                                    value: memoizedInfo.RegistrationDetails.NPO
                                }
                            ]}
                        />
                    </Stack>
                </Stack>
            </Container>
            <Box width='100vw' bgcolor='#bfbfbf' py={1}>
                <Container maxWidth='lg' component='section'>
                    <Stack
                        direction={{ sm: 'row' }}
                        spacing={1}
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <Typography
                            component='a'
                            href='http://vermelion.net'
                            target='_blank'
                            variant='caption'
                        >
                            &copy; Developed by Vermelion{' '}
                            {new Date().getFullYear()}
                        </Typography>
                        <Stack direction='row' spacing={{ xs: 0.5, sm: 1 }}>
                            <Action
                                av='IconButton'
                                size='small'
                                href='mailto:cape2care@dwfc.org.za'
                                target='_blank'
                                variant='text'
                                color='inherit'
                                Icon={<Mail />}
                                label={memoizedInfo.ContactDetails.Email}
                            />
                            <Action
                                av='IconButton'
                                href='https://instagram.com/cape2care?utm_medium=copy_link'
                                target='_blank'
                                color='inherit'
                                size='small'
                                Icon={<Instagram fontSize='small' />}
                            />
                            <Action
                                av='IconButton'
                                href='https://www.facebook.com/Cape2Care/'
                                target='_blank'
                                size='small'
                                color='inherit'
                                Icon={<Facebook fontSize='small' />}
                            />
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
}
export default React.memo(Footer);
