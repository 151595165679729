import React from 'react';
import { Loading, Navbar } from '../components';
import { useAuth, useSponsors } from '../context';
import { useFetch } from '../hooks';
import Table from '../components/table/Table';
import moment from 'moment';
import { dateFormats } from '../config';
import Typography from '@mui/material/Typography';

function AdminContributorsPage(): React.ReactElement {
    const { auth } = useAuth();
    const {
        getContributors,
        contributors,
        contributorsLoading,
        addContributor
    } = useSponsors();
    const [post] = useFetch('/sponsors/contributor/add');
    const [total, setTotal] = React.useState(0);
    const addContributorHandler = React.useCallback(
        async contributor => {
            await post(
                {
                    data: {
                        ...contributor,
                        MemberGuid: auth.MemberGuid,
                        SessionGuid: auth.SessionGuid
                    }
                },
                'Added contributor'
            );
            addContributor({
                ...contributor,
                DateAdded: moment(new Date()).format(dateFormats.normal)
            });
        },
        [addContributor, auth.MemberGuid, auth.SessionGuid, post]
    );
    React.useEffect(() => {
        getContributors();
    }, [getContributors]);
    React.useEffect(() => {
        setTotal(0);
        contributors.forEach(contributor =>
            setTotal(prevTotal => prevTotal + contributor.Amount)
        );
    }, [contributors]);
    return (
        <Navbar disableFooter>
            <Loading loading={contributorsLoading}>
                <Table
                    data={contributors.map(contributor => ({
                        ...contributor,
                        Amount:
                            'R' +
                            contributor.Amount.toString().replace(
                                /(\d)(?=(\d{3})+(?!\d))/g,
                                '$1 '
                            )
                    }))}
                    loading={contributorsLoading}
                    title='Contributors'
                    columns={['FullName', 'Link', 'Amount', 'DateAdded']}
                    sortColumns={['FullName', 'DateAdded']}
                    editableColumns={['FullName', 'Link', 'Amount']}
                    initialRow={{
                        FullName: '',
                        Link: null,
                        Amount: '0'
                    }}
                    sortByDefault='DateAdded'
                    actionsPosition='end'
                    saveEdit={addContributorHandler}
                    editable
                />
                <Typography
                    variant='subtitle1'
                    component='legend'
                    fontWeight={500}
                    m={2}
                    mt={1}
                >
                    Total: R{' '}
                    {total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}
                </Typography>
            </Loading>
        </Navbar>
    );
}

export default React.memo(AdminContributorsPage);
