import React from 'react';
import {
    // Blogs,
    HomeHeader,
    Navbar,
    Needs,
    Wishlist,
    Sponsors,
    SponsorsLogos
} from '../components';
import { useSponsors } from '../context';

function HomePage(): React.ReactElement {
    const { getContributors } = useSponsors();

    React.useEffect(() => {
        getContributors();
    }, [getContributors]);

    return (
        <Navbar>
            <HomeHeader />
            {/* <Blogs /> */}
            <SponsorsLogos />
            <Needs />
            <Sponsors />
            <Wishlist />
        </Navbar>
    );
}

export default React.memo(HomePage);
