import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Action, DonateMoney, DonateOther, Navbar } from '../components';
import Paper from '@mui/material/Paper';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Donation from '../assets/images/donation.png';
import DonationItem from '../assets/images/donate_item.png';
import useConfig from '../context/useConfig';

function DonatePage(): React.ReactElement {
    const { donateOptionState, dispatchConfig } = useConfig();
    const changeDisplay = React.useCallback(
        (num: 0 | 1 | 2) => () =>
            dispatchConfig({ type: 'SET_DONATE_OPTION', option: num }),
        [dispatchConfig]
    );
    return (
        <Navbar
            sxContainer={{
                py: 4,
                minHeight: '100vh',
                display: 'grid',
                placeContent: 'center'
            }}
        >
            <Paper
                sx={{
                    py: 2,
                    px: donateOptionState === 0 ? 3 : 2,
                    bgcolor: 'background.paper'
                }}
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    mb={2}
                    width='100%'
                >
                    {donateOptionState !== 0 && (
                        <Action
                            av='IconButton'
                            Icon={<ArrowBackIosIcon />}
                            onClick={changeDisplay(0)}
                        />
                    )}
                    <Typography
                        variant='subtitle1'
                        component='legend'
                        ml='auto'
                    >
                        {donateOptionState === 0
                            ? 'Select a donation option'
                            : donateOptionState === 1
                            ? 'Make a donation'
                            : 'What would you like to donate?'}
                    </Typography>
                </Stack>
                {donateOptionState === 0 ? (
                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
                        <Action
                            av='Image'
                            src={Donation}
                            maxWidth='13rem'
                            onClick={changeDisplay(1)}
                        />
                        <Action
                            av='Image'
                            src={DonationItem}
                            maxWidth='13rem'
                            onClick={changeDisplay(2)}
                        />
                    </Stack>
                ) : donateOptionState === 1 ? (
                    <DonateMoney />
                ) : (
                    <DonateOther />
                )}
            </Paper>
            {/*  */}
        </Navbar>
    );
}

export default React.memo(DonatePage);
