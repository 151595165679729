import React from 'react';

interface IDimensions {
    width: number;
    height: number;
}

function getDimensions(): IDimensions {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useDimensions(): IDimensions {
    const [windowDimensions, setWindowDimensions] = React.useState(
        getDimensions()
    );

    React.useEffect(() => {
        function handleResize() {
            setWindowDimensions(getDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}
