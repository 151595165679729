import Pages from '../pages';
import routeTypes from '../config/routes.config';

interface NavigationProps {
    path: routeTypes;
    component: React.FC;
}

export const navigator: {
    public: Array<NavigationProps>;
    admin: Array<NavigationProps>;
} = {
    public: [
        {
            path: '/',
            component: Pages.Home
        },
        {
            path: '/blogs',
            component: Pages.Blog
        },
        {
            path: '/why-we-walk',
            component: Pages.WhyWeWalk
        },
        {
            path: '/donate',
            component: Pages.Donate
        },
        {
            path: '/login',
            component: Pages.Login
        }
    ],
    admin: [
        {
            path: '/admin',
            component: Pages.AdminContributors
        },
        {
            path: '/admin/contributors',
            component: Pages.AdminContributors
        },
        {
            path: '/logoff',
            component: Pages.Logoff
        }
    ]
};
