import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import MuiIconButton, {
    IconButtonProps as MuiIconButtonProps
} from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import useNavigate from '../../../hooks/useNavigate';
import { LordIcon } from '../..';
import { DefaultButtonProps } from './Action.Button';
import { WithActionProps } from './Action';
import { WithLink } from '../../../config/routes.config';

export type WithIconButtonProps<T = {}> = WithActionProps<
    T & MuiIconButtonProps & DefaultButtonProps
>;

function ActionIconButton(props: WithLink<WithIconButtonProps>) {
    const { path, navigateOnClick } = useNavigate();
    return (
        <MuiIconButton
            disabled={
                props.loading || (props.to?.path === path && !props.activeColor)
            }
            {...props}
            color={
                props?.to?.path === path && props.activeColor
                    ? props.activeColor
                    : props.color ?? 'primary'
            }
            onClick={
                props.to
                    ? navigateOnClick(props.to, props.onClick)
                    : props.onClick
            }
        >
            {props.loading ? (
                <CircularProgress
                    color={props.loadingColor ? props.loadingColor : 'primary'}
                    size={16}
                    {...props.CircularProgressProps}
                />
            ) : props.badge ? (
                <Badge badgeContent={props.badge} {...props.BadgeProps}>
                    {props.children ? (
                        props.children
                    ) : props.LordIcon ? (
                        <LordIcon {...props.LordIcon} />
                    ) : props.Icon ? (
                        props.Icon
                    ) : (
                        props.label
                    )}
                </Badge>
            ) : props.children ? (
                props.children
            ) : props.LordIcon ? (
                <LordIcon {...props.LordIcon} />
            ) : props.Icon ? (
                props.Icon
            ) : (
                props.label
            )}
        </MuiIconButton>
    );
}

export default React.memo(ActionIconButton);
