import React from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Hooks from '../hooks';
import useAuth from '../context/useAuth';
import { Form, Action, Navbar } from '../components';

function LoginPage(): React.ReactElement {
    Hooks.useTabName('Cape2Care | Login');
    const { dispatchAuth, checkSession } = useAuth();
    const [post, loading] = Hooks.useFetch('/users/login');
    const [showPassword, togglePassword] = Hooks.useBoolean();
    const [loginValues, onChange] = Hooks.useForm({
        Email: '',
        Password: ''
    });

    const loginHandler = React.useCallback(async () => {
        const response = await post({ data: loginValues }, 'Login successful!');
        response.result === 'success'
            ? (() => {
                  dispatchAuth({ type: 'LOGIN', session: response.data });
                  checkSession(response.data, 'login');
              })()
            : dispatchAuth({ type: 'LOGOUT' });
    }, [post, loginValues, dispatchAuth, checkSession]);

    return (
        <Navbar sxContainer={{ placeContent: 'center', display: 'grid' }}>
            <Form
                onFormSubmit={loginHandler}
                title='Cape2Care Login'
                label='Login'
                Fields={[
                    {
                        name: 'Email',
                        onChange: onChange('Email'),
                        autoFocus: true
                    },
                    {
                        name: 'Password',
                        onChange: onChange('Password'),
                        type: showPassword ? 'text' : 'password',
                        endAdornment: (
                            <Action
                                av='IconButton'
                                label='toggle password'
                                Icon={
                                    showPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )
                                }
                                onClick={togglePassword}
                            />
                        )
                    }
                ]}
                loading={loading}
            />
        </Navbar>
    );
}

export default React.memo(LoginPage);
