import React from 'react';
import {
    authReducerInitialState,
    IAuthReducer,
    authActionTypes,
    ISession
} from './auth.reducer';

interface AuthContextProps extends IAuthReducer {
    auth: IAuthReducer;
    dispatchAuth: React.Dispatch<authActionTypes>;
    logout: () => void;
    checkSession: (session: ISession, type: 'login' | 'session') => void;
}

export const AuthContext = React.createContext<AuthContextProps>({
    ...authReducerInitialState,
    auth: authReducerInitialState,
    dispatchAuth: () => {},
    logout: () => {},
    checkSession: () => {}
});

export const useAuth = () => React.useContext(AuthContext);
