import React from 'react';

export default function useBoolean(
    initialValue: boolean = false
): [state: boolean, toggleState: (condition?: boolean | any) => void] {
    const [state, setState] = React.useState<boolean>(initialValue);

    const toggleState = React.useCallback(
        (condition?: boolean | any) =>
            setState(prevState =>
                typeof condition === 'boolean' ? condition : !prevState
            ),
        []
    );

    return [state, toggleState];
}
