import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import useConfig from '../../context/useConfig';
import { OptimisedHead, OptimisedToolbar, useOptimisedTable } from '.';
import { OptimisedTableProps } from './OptimisedTable.types';

export const OptimisedTable: React.FC<OptimisedTableProps> = React.memo(
    ({
        data,
        title,
        loading,
        orderByInitial,
        headList,
        searchOptions,
        removeDuplicates,
        disableFilter,
        disableToolbar,
        filterHandler,
        TableProps,
        TableHeadProps,
        TableBodyProps,
        DataComponent,
        Settings,
        Actions,
        Tabs
    }) => {
        const { tableSettings } = useConfig();
        const {
            order,
            orderBy,
            page,
            rowsPerPage,
            getComparator,
            onChangeSort,
            changePageHandler,
            changeRowsPerPageHandler,
            search,
            searchOptionHandler,
            searchHandler,
            filterFunction,
            removeDuplicatesHandler
        } = useOptimisedTable(orderByInitial, removeDuplicates);

        const emptyRows =
            page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

        const filter = disableFilter
            ? () => (data: any) => data
            : filterHandler
            ? filterHandler
            : filterFunction;

        return (
            <TableContainer component={Paper}>
                {!disableToolbar && (
                    <OptimisedToolbar
                        title={title}
                        numOfItems={data?.filter(filter(search)).length}
                        searchOptions={searchOptions}
                        searchOptionHandler={searchOptionHandler}
                        searchHandler={searchHandler}
                        Settings={Settings}
                        Actions={Actions}
                    />
                )}
                {Tabs}
                <Table
                    stickyHeader
                    aria-label={`${title} optimised table`}
                    size={tableSettings.dense ? 'small' : 'medium'}
                    {...TableProps}
                >
                    <OptimisedHead
                        headList={headList}
                        order={order}
                        orderBy={orderBy}
                        onChangeSort={onChangeSort}
                        TableHeadProps={TableHeadProps}
                    />
                    {loading || !data ? (
                        <TableBody {...TableBodyProps}>
                            <TableRow>
                                <TableCell
                                    padding='none'
                                    colSpan={headList.length}
                                >
                                    <LinearProgress sx={{ width: '100%' }} />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody {...TableBodyProps}>
                            {data
                                .sort(getComparator(order, orderBy))
                                .filter(removeDuplicatesHandler)
                                .filter(filter(search))
                                .slice(
                                    tableSettings.disablePagination
                                        ? 0
                                        : page * rowsPerPage,
                                    tableSettings.disablePagination
                                        ? data.length
                                        : page * rowsPerPage + rowsPerPage
                                )
                                .map(item => (
                                    <DataComponent key={item.Guid} {...item} />
                                ))}
                            {!tableSettings.disablePagination && emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height:
                                            (tableSettings.dense ? 33 : 53) *
                                            emptyRows
                                    }}
                                >
                                    <TableCell colSpan={headList.length} />
                                </TableRow>
                            )}
                        </TableBody>
                    )}
                </Table>
                {!tableSettings.disablePagination && !loading && (
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component='div'
                        count={data?.filter(filter(search)).length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={changePageHandler}
                        onRowsPerPageChange={changeRowsPerPageHandler}
                    />
                )}
            </TableContainer>
        );
    }
);
