import React from 'react';
import { OrderTypes } from './OptimisedTable.types';
import { InputEventProps } from '..';

export const useOptimisedTable = (
    orderByInitial: keyof any,
    removeDuplicates?: boolean
) => {
    const [order, setOrder] = React.useState<OrderTypes>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof any>(orderByInitial);
    const [page, setPage] = React.useState(0);
    const [search, setSearch] = React.useState<string | null>(null);

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const descendingComparator = React.useCallback(
        (a: any, b: any, orderBy: keyof any) =>
            b[orderBy] < a[orderBy] ? -1 : b[orderBy] > a[orderBy] ? 1 : 0,
        []
    );

    const getComparator = React.useCallback(
        <Key extends keyof any>(
            order: OrderTypes,
            orderBy: Key
        ): ((
            a: { [key in Key]: number | string },
            b: { [key in Key]: number | string }
        ) => number) =>
            order === 'desc'
                ? (a, b) => descendingComparator(a, b, orderBy)
                : (a, b) => -descendingComparator(a, b, orderBy),
        [descendingComparator]
    );

    const onChangeSort = React.useCallback(
        (property?: keyof any) => () => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            property && setOrderBy(property);
        },
        [order, orderBy]
    );

    const changePageHandler = React.useCallback(
        (_event: unknown, newPage: number) => setPage(newPage),
        []
    );
    const searchHandler = React.useCallback(
        (e: InputEventProps) => setSearch(e.target.value),
        []
    );
    const searchOptionHandler = React.useCallback(
        (e: any, newValue) => setSearch(newValue),
        []
    );

    const changeRowsPerPageHandler = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        },
        []
    );

    const filterFunction = () => (item: any) =>
        search
            ? JSON.stringify(item).toLowerCase().includes(search.toLowerCase()) &&
              item
            : item;

    const removeDuplicatesHandler = (e: any, i: any, a: any) => {
        return removeDuplicates ? (a[i - 1] && a[i - 1].Name) !== (e && e.Name) : e;
    };

    return {
        order,
        orderBy,
        page,
        search,
        rowsPerPage,
        getComparator,
        onChangeSort,
        changePageHandler,
        changeRowsPerPageHandler,
        searchOptionHandler,
        searchHandler,
        filterFunction,
        removeDuplicatesHandler
    };
};
