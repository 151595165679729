import { Add } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Action } from '..';
import { Palette } from '../../config';

interface ToolbarProps<GenericData> {
    title?: string;
    add: boolean;
    toggleAdd: (condition?: any) => void;
}

function Toolbar<ToolbarGenericData>({
    title,
    add,
    toggleAdd
}: ToolbarProps<ToolbarGenericData>): React.ReactElement {
    return (
        <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            width='100%'
            spacing={1.5}
            p={2}
        >
            <Typography
                variant='h4'
                component='h4'
                fontWeight={500}
                sx={{ wordWrap: 'normal' }}
                flex={1}
            >
                {title ?? 'Optimised Table'}
            </Typography>
            <Stack
                direction='row'
                justifyContent='flex-end'
                alignItems='center'
                spacing={1.5}
            >
                {/* <TextField
                    label='Search:'
                    variant='standard'
                    type='search'
                    size='small'
                    sx={{ maxWidth: '25rem' }}
                    InputLabelProps={{
                        shrink: true
                    }}
                    fullWidth
                    autoFocus
                /> */}
                <Action
                    av='IconButton'
                    Icon={add ? <Close /> : <Add />}
                    color='inherit'
                    sx={{
                        bgcolor: Palette.secondary?.main,
                        color: '#eee',
                        ':hover': {
                            bgcolor: Palette.secondary?.main,
                            opacity: 0.9
                        }
                    }}
                    onClick={toggleAdd}
                    disableFocusRipple
                />
            </Stack>
        </Stack>
    );
}

export default React.memo(Toolbar);
