import { console_log } from '../../config/config';
export type RoleTypes = null | 'ADMIN' | 'SYSADMIN' | 0 | 1;

export interface ISession {
    MemberGuid: string | null | undefined;
    SessionGuid: string | null | undefined;
    RoleName: RoleTypes;
    Name?: RoleTypes;
}
export interface IAuthReducer extends ISession {}
export const authReducerInitialState: IAuthReducer = {
    MemberGuid: null,
    SessionGuid: null,
    RoleName: null
};

export const authReducerInit = (initialState: IAuthReducer) => ({
    MemberGuid: localStorage.getItem('MemberGuid')
        ? localStorage.getItem('MemberGuid')
        : initialState.MemberGuid,
    SessionGuid: localStorage.getItem('SessionGuid')
        ? localStorage.getItem('SessionGuid')
        : initialState.SessionGuid,
    RoleName: initialState.RoleName
});

export type authActionTypes =
    | { type: 'RESET' }
    | { type: 'LOGIN'; session: ISession }
    | { type: 'UPDATE_ROLE'; Name?: RoleTypes; RoleName: RoleTypes }
    | { type: 'LOGOUT' };

export const authReducer = (state: IAuthReducer, action: authActionTypes) => {
    console_log(action.type, '\n', action);
    console_log('====================================');
    switch (action.type) {
        case 'RESET':
            return authReducerInit(authReducerInitialState);
        case 'UPDATE_ROLE':
            return {
                ...state,
                RoleName: action.Name ?? action.RoleName
            };
        case 'LOGIN':
            action.session.MemberGuid &&
                localStorage.setItem('MemberGuid', action.session.MemberGuid);
            action.session.SessionGuid &&
                localStorage.setItem('SessionGuid', action.session.SessionGuid);
            return {
                ...state,
                ...action.session
            };
        case 'LOGOUT':
            localStorage.removeItem('MemberGuid');
            localStorage.removeItem('SessionGuid');
            return authReducerInit(authReducerInitialState);
        default:
            return state;
    }
};
