import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import useAuth from '../../context/useAuth';
import Hooks from '../../hooks';
import { console_log } from '../../config';
import { Action, Form } from '..';

const Input = styled('input')({
    display: 'none'
});

function AddBlog(): React.ReactElement {
    const { auth } = useAuth();
    const [post, loading] = Hooks.useFetch('/blogs/create');
    const [postImage, imageLoading] = Hooks.useFetch('/blogs/image');
    const [blog, onChange, reset] = Hooks.useForm({
        title: '',
        description: ''
    });
    const [images, setImages] = React.useState<FileList | null>(null);

    const addBlogHandler = React.useCallback(async () => {
        // console.log(images);
        // const response = await post({ data: blog, token: Token });

        // response.res
        //     ? (() => {
        //           alert.success('Added Blog!');
        //       })()
        //     : (() => {
        //           resetForm();
        //           alert.error(response.msg);
        //           console.log('RESPONSE: ', response);
        //           console.log('TOKEN: ', Token);
        //       })();

        // console.log('ID: ', response.data.id);

        const imageResponse = await postImage({
            ext: '2021-10-31 16:51:49.172921Blog Test Title',
            data: images
            // token: Token
        });
        console_log(imageResponse);
    }, [images, postImage]);
    return (
        <>
            <Form
                onFormSubmit={addBlogHandler}
                label='Add Blog'
                reset={reset}
                Fields={[
                    { name: 'Title', onChange: onChange('title') },
                    { name: 'Description', onChange: onChange('description') }
                ]}
                loading={loading}
            >
                <label htmlFor='contained-button-file'>
                    <Input
                        accept='image/*'
                        id='contained-button-file'
                        multiple
                        type='file'
                        onChange={e => setImages(e.target.files)}
                    />
                    <Action
                        av='Button'
                        label='Upload'
                        startIcon={<PhotoCamera />}
                        component='span'
                        grid
                    />
                    {/* <Button variant='contained' component='span'>
                        Upload
                    </Button> */}
                </label>
                {/* <Typography>{images?.map(image => image.filename)}</Typography> */}
            </Form>
        </>
    );
}

export default React.memo(AddBlog);
