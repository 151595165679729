import React from 'react';
import {
    AlertProvider,
    AuthProvider,
    ConfigProvider,
    SponsorsProvider
} from '.';

function Providers({ children }: WithChildren): React.ReactElement {
    return (
        <AlertProvider>
            <AuthProvider>
                <ConfigProvider>
                    <SponsorsProvider>{children}</SponsorsProvider>
                </ConfigProvider>
            </AuthProvider>
        </AlertProvider>
    );
}

export default React.memo(Providers);
