import React from 'react';
import Grid, { GridSize } from '@mui/material/Grid';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';

export type InputEventProps = React.ChangeEvent<HTMLInputElement>;

interface IFieldOption {
    value: string;
    label?: string;
}

export interface FieldProps extends StandardTextFieldProps {
    lg?: boolean | GridSize | undefined;
    md?: boolean | GridSize | undefined;
    sm?: boolean | GridSize | undefined;
    xs?: boolean | GridSize | undefined;
    switch?: boolean;
    checkbox?: boolean;
    optional?: boolean | undefined;
    radioOptions?: Array<IFieldOption>;
    options?: Array<IFieldOption>;
    labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
    fieldVariant?: 'outlined' | 'standard' | 'filled' | undefined;
    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
    CustomSwitch?: any;
    CheckboxProps?: CheckboxProps;
}

const Field: React.FC<FieldProps> = React.memo(
    ({
        xs,
        sm,
        md,
        lg,
        labelPlacement,
        fieldVariant,
        checkbox,
        optional,
        radioOptions,
        options,
        startAdornment,
        endAdornment,
        CustomSwitch,
        ...textFieldProps
    }) => {
        return (
            <Grid item xs={xs ? xs : 12} sm={sm} md={md} lg={lg}>
                {textFieldProps.switch ? (
                    <FormControlLabel
                        control={
                            CustomSwitch ? (
                                <CustomSwitch
                                    color={textFieldProps.color}
                                    size={textFieldProps.size}
                                    disabled={textFieldProps.disabled}
                                    onChange={textFieldProps.onChange}
                                    checked={textFieldProps.value === true}
                                />
                            ) : (
                                <Switch
                                    color={textFieldProps.color}
                                    size={textFieldProps.size}
                                    disabled={textFieldProps.disabled}
                                    onChange={textFieldProps.onChange}
                                    checked={textFieldProps.value === true}
                                />
                            )
                        }
                        label={textFieldProps.label ?? textFieldProps.name}
                        labelPlacement={labelPlacement}
                    />
                ) : checkbox ? (
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={textFieldProps.color}
                                size={textFieldProps.size}
                                disabled={textFieldProps.disabled}
                                onChange={textFieldProps.onChange}
                                checked={textFieldProps.value === true}
                            />
                        }
                        label={textFieldProps.label ?? textFieldProps.name}
                        labelPlacement={labelPlacement}
                    />
                ) : radioOptions ? (
                    <FormControl component='fieldset'>
                        <FormLabel component='legend'>
                            {textFieldProps.label ?? textFieldProps.name}
                        </FormLabel>
                        <RadioGroup
                            aria-label={textFieldProps.name ?? 'radio group'}
                            name={`${
                                textFieldProps.label ?? textFieldProps.name
                            }-radio-buttons'`}
                            value={textFieldProps.value}
                            onChange={textFieldProps.onChange}
                            color={textFieldProps.color}
                        >
                            {radioOptions?.map(option => (
                                <FormControlLabel
                                    value={option.value}
                                    control={<Radio />}
                                    label={option.label ?? option.value}
                                    labelPlacement={labelPlacement}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                ) : (
                    <TextField
                        fullWidth
                        {...textFieldProps}
                        InputProps={{
                            startAdornment: startAdornment && (
                                <InputAdornment position='start'>
                                    {startAdornment}
                                </InputAdornment>
                            ),
                            endAdornment: endAdornment && (
                                <InputAdornment position='end'>
                                    {endAdornment}
                                </InputAdornment>
                            )
                        }}
                        label={`${
                            textFieldProps.label
                                ? textFieldProps.label
                                : textFieldProps.name
                        }:`}
                        variant={fieldVariant}
                        required={!optional}
                        select={options !== undefined}
                    >
                        {options?.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label ?? option.value}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            </Grid>
        );
    }
);

export default React.memo(Field);
