import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useSponsors from '../../context/useSponsors';
import { radius } from '../../config';

function Progress(): React.ReactElement {
    const { contributors } = useSponsors();
    const [progress, setProgress] = React.useState<number>(0);
    const calcPercentage = (amount: number): number => {
        const total = 5 * 10 ** 6;
        const calc = amount / total;
        return typeof +(calc * 100).toFixed(2) !== 'number'
            ? 0
            : +(calc * 100).toFixed(2);
    };
    React.useEffect(() => {
        contributors.forEach(contributor =>
            setProgress(prevProgress =>
                typeof contributor.Amount !== 'number'
                    ? 0
                    : contributor.Amount + prevProgress
            )
        );
    }, [contributors]);

    return (
        <>
            <Typography variant='h5' textAlign='center' fontWeight={600} my={3}>
                Our goal: R5,000,000
            </Typography>
            <Stack
                direction='row'
                width='100%'
                alignItems='center'
                justifyContent='space-between'
            >
                <Typography variant='caption' justifySelf='flex-end'>
                    0%
                </Typography>
                <Typography variant='caption' justifySelf='flex-end'>
                    100%
                </Typography>
            </Stack>
            <Box
                bgcolor='darkgrey'
                width='100%'
                height={6}
                borderRadius={radius.default}
                overflow='hidden'
            >
                <Box
                    bgcolor='secondary.main'
                    width={`${calcPercentage(progress)}%`}
                    height='100%'
                />
            </Box>
            <Stack width='100%' position='relative'>
                <Typography
                    variant='caption'
                    position='absolute'
                    left={`${calcPercentage(progress)}%`}
                    sx={{
                        transform:
                            calcPercentage(progress) === 0
                                ? 'none'
                                : 'translateX(-50%)'
                    }}
                >
                    {calcPercentage(progress)}%
                </Typography>
            </Stack>
        </>
    );
}
export default React.memo(Progress);
