import React from 'react';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Badge, { BadgeProps } from '@mui/material/Badge';
import useNavigate from '../../../hooks/useNavigate';
import { LordIcon, LordIconProps, WithActionProps } from '../..';
import { WithLink } from '../../../config/routes.config';
export interface DefaultButtonProps {
    disableLinkActiveState?: boolean;
    activeColor?: ColorOptions;
    Icon?: React.ReactNode;
    LordIcon?: LordIconProps;
    BadgeProps?: BadgeProps;
    badge?: React.ReactNode;
    upload?: boolean;
}

export type WithButtonProps<T = {}> = WithActionProps<
    T &
        DefaultButtonProps &
        MuiButtonProps & {
            disableFullWidth?: boolean;
            ButtonProps?: MuiButtonProps;
            startLordIcon?: LordIconProps;
        }
>;

function ActionButton(props: WithLink<WithButtonProps>) {
    const { path, navigateOnClick } = useNavigate();
    return (
        <MuiButton
            disabled={
                props.loading ||
                (props.to?.path === path &&
                    !props.activeColor &&
                    !props.disableLinkActiveState)
            }
            variant='contained'
            startIcon={
                props.startLordIcon ? (
                    <LordIcon {...props.startLordIcon} />
                ) : (
                    props.loading && (
                        <CircularProgress
                            color={
                                props.loadingColor
                                    ? props.loadingColor
                                    : 'primary'
                            }
                            size={18}
                        />
                    )
                )
            }
            fullWidth={!props.disableFullWidth}
            {...props}
            color={
                !props.disableLinkActiveState &&
                props?.to?.path === path &&
                props.activeColor
                    ? props.activeColor
                    : props.color ?? 'primary'
            }
            onClick={
                props.to
                    ? navigateOnClick(props.to, props.onClick)
                    : props.onClick
            }
        >
            {props.badge ? (
                <Badge badgeContent={props.badge} {...props.BadgeProps}>
                    {props.children ? (
                        props.children
                    ) : props.LordIcon ? (
                        <LordIcon {...props.LordIcon} />
                    ) : props.Icon ? (
                        props.Icon
                    ) : (
                        props.label
                    )}
                </Badge>
            ) : props.children ? (
                props.children
            ) : props.LordIcon ? (
                <LordIcon {...props.LordIcon} />
            ) : props.Icon ? (
                props.Icon
            ) : (
                props.label
            )}
        </MuiButton>
    );
}
export default React.memo(ActionButton);
