import { AxiosRequestConfig } from 'axios';

export const GET = [
    '/blogs/all',
    '/blogs',
    '/sponsors',
    '/sponsors/contributors'
] as const;
export const POST = [
    '/blogs/create',
    '/blogs/image',
    '/contact',
    '/contact/donate',
    '/contact/donate-money',
    '/sponsors/add',
    '/sponsors/contributor/add',
    '/users/checkSession',
    '/users/login',
    '/users/logout'
] as const;
export const PUT = ['/blogs/edit'] as const;
export const DELETE = [] as const;
export const ROUTES = { GET, POST, PUT, DELETE };
export interface IDataResponse {
    result: 'catch error' | 'error' | 'success';
    data: unknown | any | null;
    programmerEndMessage?: string;
    frontEndMessage?: string;
}

export type fetchHandlerType = (
    opt?: {
        q?: string;
        ext?: string;
    } & AxiosRequestConfig<any>,
    message?: string | boolean
) => Promise<IDataResponse>;

export const catchResponse: (err: any | unknown) => IDataResponse = err => ({
    result: 'catch error',
    data: err,
    frontEndMessage: 'There was a catch error. Contact admin.vermelion.net',
    programmerEndMessage: 'Catch Error'
});
