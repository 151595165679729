import { TextField } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import React from 'react';
import { useBoolean, useForm } from '../../hooks';
import TableButtons, { ITableAction } from './Table.Buttons';

export interface WithTableDataProps<GenericData> {
    data: GenericData[];
    columns: Array<keyof GenericData>;
    editableColumns?: Array<keyof GenericData>;
    sortColumns?: Array<keyof GenericData>;
    saveEdit?: (row: GenericData) => void;
    toggleAdd?: (condition?: any) => void;
    editable?: boolean;
    actionsPosition?: 'start' | 'end';
    actions?: Array<ITableAction<GenericData>>;
}

interface TableDataProps<GenericRow> extends WithTableDataProps<GenericRow> {
    row: GenericRow;
    add?: boolean;
}

function TableData<DataGenericRow>({
    row,
    add,
    columns,
    editableColumns,
    toggleAdd,
    saveEdit,
    editable,
    actionsPosition,
    actions
}: TableDataProps<DataGenericRow>) {
    const [edit, toggleEdit] = useBoolean(add);
    const [submitData, onChange, reset] = useForm(row);
    const cancel = React.useCallback(() => {
        reset();
        toggleEdit(false);
        toggleAdd && toggleAdd();
    }, [reset, toggleEdit, toggleAdd]);
    return (
        <TableRow>
            {actionsPosition === 'start' && (
                <TableButtons
                    add={add}
                    toggleAdd={toggleAdd}
                    defaultData={row}
                    submitData={submitData}
                    edit={edit}
                    saveEdit={saveEdit}
                    toggleEdit={toggleEdit}
                    editable={editable}
                    actions={actions}
                    row={row}
                />
            )}
            {columns.map((column, index) => (
                <TableCell
                    key={index}
                    component={
                        index === 0 &&
                        (actionsPosition === 'end' || !actions) &&
                        (!editable || actionsPosition === 'end')
                            ? 'th'
                            : 'td'
                    }
                    scope={
                        index === 0 &&
                        (actionsPosition === 'end' || !actions) &&
                        (!editable || actionsPosition === 'end')
                            ? 'row'
                            : undefined
                    }
                >
                    {edit && editableColumns?.includes(column) ? (
                        <TextField
                            size='small'
                            variant='standard'
                            fullWidth={false}
                            defaultValue={row[column]}
                            onChange={onChange(column)}
                            onKeyUp={e => e.key === 'Escape' && cancel()}
                            autoFocus={index === 0}
                        />
                    ) : (
                        row[column]
                    )}
                </TableCell>
            ))}
            {actionsPosition === 'end' && (
                <TableButtons
                    add={add}
                    toggleAdd={toggleAdd}
                    defaultData={row}
                    submitData={submitData}
                    edit={edit}
                    saveEdit={saveEdit}
                    toggleEdit={toggleEdit}
                    editable={editable}
                    actions={actions}
                    row={row}
                />
            )}
        </TableRow>
    );
}

export default TableData;
