import { useCallback, useReducer } from 'react';
import { adminRoutes } from '../../config/routes.config';
import type_includes from '../../functions/type_includes';
import useNavigate from '../../hooks/useNavigate';
import { AuthContext } from './Auth.Context';
import {
    authReducer,
    authReducerInit,
    authReducerInitialState,
    ISession
} from './auth.reducer';

export default function AuthProvider({ children }: WithChildren) {
    const { navigate, path } = useNavigate();
    const [auth, dispatchAuth] = useReducer(
        authReducer,
        authReducerInitialState,
        authReducerInit
    );

    const checkSession = useCallback(
        (session: ISession, type: 'login' | 'session') => {
            type === 'login'
                ? dispatchAuth({ type: 'LOGIN', session: session })
                : dispatchAuth({
                      type: 'UPDATE_ROLE',
                      Name: session.Name,
                      RoleName: session.RoleName
                  });
            (session.Name === 'ADMIN' ||
                session.RoleName === 'ADMIN' ||
                session.Name === 'SYSADMIN' ||
                session.RoleName === 'SYSADMIN') &&
                (path === '/login' || !type_includes(adminRoutes, path)) &&
                navigate('/admin');
        },
        [navigate, path]
    );

    const logout = useCallback(() => {
        dispatchAuth({ type: 'LOGOUT' });
        navigate('/login');
    }, [navigate]);

    return (
        <AuthContext.Provider
            value={{
                ...auth,
                auth,
                dispatchAuth,
                logout,
                checkSession
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
