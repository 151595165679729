import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { OptimisedHeadProps } from './OptimisedTable.types';

export const OptimisedHead: React.FC<OptimisedHeadProps> = React.memo(
    ({ headList, order, orderBy, onChangeSort, TableHeadProps }) => (
        <TableHead {...TableHeadProps}>
            <TableRow>
                {headList.map((head, id) =>
                    head.disableSort ? (
                        <TableCell key={id} {...head.CellProps}>
                            {head.label ? head.label : head.property}
                        </TableCell>
                    ) : (
                        <TableCell
                            key={id}
                            sortDirection={orderBy === head.property ? order : false}
                            {...head.CellProps}
                        >
                            <TableSortLabel
                                active={orderBy === head.property}
                                direction={orderBy === head.property ? order : 'asc'}
                                onClick={onChangeSort(head.property)}
                            >
                                {head.label ? head.label : head.property}
                            </TableSortLabel>
                        </TableCell>
                    )
                )}
            </TableRow>
        </TableHead>
    )
);
