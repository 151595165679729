import React from 'react';
import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { Action, WithButtonProps, Form, FormProps } from '..';

export interface DialogProps extends MuiDialogProps {
    open: boolean;
    onClose: () => void;
    label?: string | undefined;
    form?: FormProps;
    deny?: WithButtonProps;
    accept?: WithButtonProps;
}

function Dialog({
    open,
    onClose,
    label,
    deny,
    accept,
    form,
    ...dialogProps
}: DialogProps): React.ReactElement {
    return (
        <MuiDialog
            open={open}
            onClose={onClose}
            aria-labelledby={`${label}-alert-dialog-title`}
            aria-describedby={`${label}-alert-dialog-description`}
            {...dialogProps}
        >
            <DialogTitle>{label}</DialogTitle>
            {form && <Form {...form} />}
            <DialogActions>
                {deny && (
                    <Action
                        av='Button'
                        {...deny}
                        label={deny.label ?? 'Cancel'}
                        onClick={deny.onClick ?? onClose}
                        color={deny.color ?? 'inherit'}
                        variant={deny.variant ?? 'text'}
                    />
                )}
                {accept && (
                    <Action
                        av='Button'
                        {...accept}
                        label={accept.label ?? 'Confirm'}
                        onClick={accept.onClick ?? onClose}
                        color={accept.color ?? 'primary'}
                        variant={accept.variant ?? 'text'}
                    />
                )}
            </DialogActions>
        </MuiDialog>
    );
}

export default React.memo(Dialog);
