import { GridSize } from '@mui/material/Grid';
export const SponsorsConstant: Array<{
    logo: string;
    size: GridSize;
    href?: string;
}> = [
    {
        size: 6,
        logo: '/images/logos/vermelion.png',
        href: 'https://vermelion.net'
    },
    {
        size: 6,
        logo: '/images/logos/die_groot_ontbyt.png',
        href: 'https://www.grootfm.co.za/die-groot-ontbyt/'
    }
];
