import React from 'react';
import {
    configReducerInitialState,
    configActionTypes,
    IConfigReducer
} from './config.reducer';

export interface ConfigContextProps extends IConfigReducer {
    config: IConfigReducer;
    dispatchConfig: React.Dispatch<configActionTypes>;
}

export const ConfigContext = React.createContext<ConfigContextProps>({
    ...configReducerInitialState,
    config: configReducerInitialState,
    dispatchConfig: () => {}
});

export const useConfig = () => React.useContext(ConfigContext);
