import React from 'react';
import { useFetch, useForm } from '../../hooks';
import { Form, FormType } from '..';

function ContactForm(): React.ReactElement {
    const [contactState, onChange] = useForm({
        FullName: '',
        Email: '',
        Phone: '',
        Message: ''
    });
    const [post, loading] = useFetch('/contact');

    const contactHandler = React.useCallback(
        async () => await post({ data: contactState }, 'Message Sent!'),
        [contactState, post]
    );

    const submitHandler = React.useCallback(
        (e: FormType) => {
            e.preventDefault();
            contactHandler();
        },
        [contactHandler]
    );
    return (
        <Form
            onFormSubmit={submitHandler}
            loading={loading}
            label='Send'
            spacing={1}
            size='small'
            maxWidth='xs'
            title='Get involved'
            TitleProps={{ variant: 'h4', textAlign: 'start' }}
            FieldProps={{ size: 'small', fieldVariant: 'standard' }}
            Fields={[
                { label: 'FullName', onChange: onChange('FullName') },
                { label: 'Email', onChange: onChange('Email'), sm: 6 },
                {
                    label: 'Phone',
                    onChange: onChange('Phone'),
                    sm: 6,
                    optional: true
                },
                {
                    label: 'Message',
                    onChange: onChange('Message'),
                    multiline: true
                }
            ]}
        />
    );
}
export default React.memo(ContactForm);
