import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSponsors } from '../../context';
import { Loading } from '..';

function SponsorsContribute(): React.ReactElement {
    const { contributors, contributorsLoading } = useSponsors();
    return (
        <Grid
            container
            spacing={4}
            mt={4}
            mx='auto'
            justifyContent='center'
            position='relative'
            width='100%'
        >
            <Grid item xs={12} textAlign='center'>
                <Typography variant='h5' fontWeight={600}>
                    Contributors
                </Typography>
            </Grid>
            <Loading loading={contributorsLoading} relative>
                {contributors.map(contributor => (
                    <Grid item xs={12} sm={4} lg={3} textAlign='center'>
                        <Typography variant='subtitle2'>
                            {contributor.FullName}
                        </Typography>
                    </Grid>
                ))}
            </Loading>
        </Grid>
    );
}

export default React.memo(SponsorsContribute);
