import React from 'react';
import { Breakpoint } from '@mui/material/styles';
import { BoxProps } from '@mui/material/Box';
import { StackProps } from '@mui/material/Stack';
import Container from '@mui/material/Container';
import LogoutIcon from '@mui/icons-material/Logout';
import DonnationIcon from '../../assets/images/donation_icon.png';
import ContactIcon from '../../assets/images/contact.png';
import { useAuth, useConfig } from '../../context';
import { useDimensions, useNavigate } from '../../hooks';
import { Footer, SxType, WithButtonProps, WithIconButtonProps } from '..';
import NavbarLinks from './Navbar.Links';
export interface NavbarProps extends StackProps {
    bg?: string;
    mw?: false | Breakpoint | undefined;
    disableGoBack?: boolean;
    disableFooter?: boolean;
    BoxProps?: BoxProps;
    ActionStackProps?: StackProps;
    LinksStackProps?: StackProps;
    sxContainer?: SxType;
}

function Navbar({
    disableFooter,
    children,
    sxContainer,
    ...props
}: WithChildren<NavbarProps>) {
    const { dispatchConfig } = useConfig();
    const { width } = useDimensions();
    const { RoleName } = useAuth();
    const { path } = useNavigate();
    const topRef = React.useRef<HTMLElement>(null);
    const [links, setLinks] = React.useState<WithButtonProps[] | undefined>();
    const [actions, setActions] = React.useState<
        WithIconButtonProps[] | undefined
    >();

    React.useEffect(() => topRef.current?.scrollIntoView(), [path]);

    const admin = () => {
        setLinks(undefined);
        setActions([
            {
                to: { path: '/logoff' },
                label: 'logout',
                color: 'secondary',
                Icon: <LogoutIcon />
            }
        ]);
    };

    const customer = React.useCallback(() => {
        setLinks(
            width >= 500
                ? [
                      { to: { path: '/' }, label: 'Home' },
                      //   { to: { path: '/blogs' }, label: 'Blogs' },
                      {
                          to: { path: '/why-we-walk' },
                          label: width < 600 ? 'Purpose' : 'Why we walk'
                      },
                      {
                          to: { path: '/donate' },
                          label: 'Donate',
                          variant: 'contained',
                          color: 'primary'
                      },
                      {
                          href: '#contact',
                          label: 'Get Involved',
                          variant: 'outlined',
                          color: 'primary'
                      }
                  ]
                : [
                      { to: { path: '/' }, label: 'Home' },
                      //   { to: { path: '/blogs' }, label: 'Blogs' },
                      {
                          to: { path: '/why-we-walk' },
                          label: width < 600 ? 'Purpose' : 'Why we walk'
                      }
                  ]
        );
        setActions(
            width >= 500
                ? undefined
                : [
                      {
                          to: { path: '/donate' },
                          label: 'Donate',
                          color: 'primary',
                          Icon: (
                              <img
                                  src={DonnationIcon}
                                  alt={DonnationIcon}
                                  style={{ maxWidth: '1.8rem' }}
                              />
                          )
                      },
                      {
                          href: '#contact',
                          label: 'Get Involved',
                          color: 'primary',
                          Icon: (
                              <img
                                  src={ContactIcon}
                                  alt={ContactIcon}
                                  style={{ maxWidth: '1.8rem' }}
                              />
                          )
                      }
                  ]
        );
    }, [width]);

    React.useEffect(
        () =>
            RoleName === 'ADMIN' || RoleName === 'SYSADMIN'
                ? admin()
                : customer(),
        [customer, RoleName]
    );

    return (
        <>
            <NavbarLinks
                BoxProps={{
                    top: width > 600 ? 0 : 'auto',
                    bottom: width > 600 ? 'auto' : 0
                }}
                LinkProps={{
                    variant: 'text',
                    size: 'small',
                    disableFullWidth: true,
                    activeColor: 'secondary',
                    color: 'inherit',
                    onClick: () => dispatchConfig({ type: 'RESET' })
                }}
                ActionProps={{ size: 'small' }}
                Links={links}
                LinksStackProps={{ spacing: width > 400 ? 1.5 : 0.5 }}
                {...props}
                Actions={actions}
                bg='background.default'
                disableGoBack={props.disableGoBack || width <= 600}
            />
            {children && (
                <Container
                    component='main'
                    maxWidth='xl'
                    sx={{
                        bgcolor: 'background.default',
                        pt: width > 600 ? 7 : 3,
                        minHeight: '92vh',
                        ...sxContainer
                    }}
                    ref={topRef}
                >
                    {children}
                </Container>
            )}
            {!disableFooter && <Footer mb={width > 600 ? 0 : 9} />}
        </>
    );
}
export default React.memo(Navbar);
