import React from 'react';
import { Blogs, Navbar } from '../components';

function BlogPage(): React.ReactElement {
    return (
        <Navbar>
            <Blogs />
        </Navbar>
    );
}

export default React.memo(BlogPage);
