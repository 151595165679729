import React from 'react';
import TableCell from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import Action, { WithIconButtonProps } from '../custom/action';
import Close from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';

interface TableButtonsProps<ButtonsGenericRow>
    extends Omit<TableButtonProps<ButtonsGenericRow>, 'action'>,
        EditButtonProps<ButtonsGenericRow> {
    editable?: boolean;
    actions?: ITableAction<ButtonsGenericRow>[];
    add?: boolean;
}

function TableButtons<TableButtonsGeneric>(
    props: TableButtonsProps<TableButtonsGeneric>
) {
    return (
        <>
            {props.editable && <EditButton {...props} />}
            {props.actions?.map(action =>
                !props.add ? (
                    <TableButton {...props} action={action} />
                ) : (
                    <TableCell padding='checkbox' />
                )
            )}
        </>
    );
}

export interface ITableAction<GenericRow> {
    onClick: (row: GenericRow) => void;
    icon?: React.ReactNode;
    color?: ColorOptions;
    props?: WithIconButtonProps;
}

interface TableButtonProps<GenericRow> {
    row: GenericRow;
    action: ITableAction<GenericRow>;
    head?: boolean;
}

function TableButton<ButtonGenericRow>({
    row,
    action,
    head
}: TableButtonProps<ButtonGenericRow>): React.ReactElement {
    return (
        <TableCell
            padding='checkbox'
            component={head ? 'th' : 'td'}
            scope={head ? 'row' : undefined}
        >
            <Action
                av='IconButton'
                Icon={action.icon}
                color={action.color}
                onClick={() => action.onClick(row)}
                {...action.props}
            />
        </TableCell>
    );
}

interface EditButtonProps<GenericRow> {
    defaultData: GenericRow;
    submitData: GenericRow;
    edit: boolean;
    head?: boolean;
    saveEdit?: (row: GenericRow) => void;
    toggleEdit: (condition?: any) => void;
    toggleAdd?: (condition?: any) => void;
}

function EditButton<ButtonGenericRow>({
    defaultData,
    submitData,
    edit,
    head,
    saveEdit,
    toggleEdit,
    toggleAdd
}: EditButtonProps<ButtonGenericRow>): React.ReactElement {
    const save = React.useCallback(() => {
        toggleEdit(false);
        toggleAdd && toggleAdd(false);
        saveEdit && saveEdit(submitData);
    }, [saveEdit, submitData, toggleAdd, toggleEdit]);

    const cancel = React.useCallback(() => {
        toggleEdit(false);
        toggleAdd && toggleAdd(false);
    }, [toggleAdd, toggleEdit]);

    return (
        <TableCell
            padding='checkbox'
            component={head ? 'th' : 'td'}
            scope={head ? 'row' : undefined}
        >
            <Stack direction='row'>
                <Action
                    av='IconButton'
                    Icon={edit ? <CheckIcon /> : <EditIcon />}
                    disabled={edit && defaultData === submitData}
                    onClick={edit ? save : toggleEdit}
                />
                {edit && (
                    <Action
                        av='IconButton'
                        Icon={<Close />}
                        color='error'
                        onClick={cancel}
                    />
                )}
            </Stack>
        </TableCell>
    );
}

export default React.memo(TableButtons) as typeof TableButtons;
