import React from 'react';
import axios from 'axios';
import useBoolean from '../useBoolean';
import useAlert from '../../context/useAlert';
import { console_log, routeConfig } from '../../config';
import {
    GET,
    POST,
    DELETE,
    PUT,
    ROUTES,
    IDataResponse,
    catchResponse,
    fetchHandlerType
} from './useFetch.config';
import type_includes from '../../functions/type_includes';

export default function useFetch(
    pathname:
        | typeof GET[number]
        | typeof POST[number]
        | typeof PUT[number]
        | typeof DELETE[number],
    defaultLoading: boolean = false
): [fetchHandler: fetchHandlerType, loading: boolean] {
    const alert = useAlert();
    const [loading, toggleLoading] = useBoolean(defaultLoading);
    const [method, setMethod] = React.useState<keyof typeof ROUTES>(
        type_includes(POST, pathname)
            ? 'POST'
            : type_includes(PUT, pathname)
            ? 'PUT'
            : type_includes(DELETE, pathname)
            ? 'DELETE'
            : 'GET'
    );

    React.useEffect(
        () =>
            setMethod(
                type_includes(POST, pathname)
                    ? 'POST'
                    : type_includes(PUT, pathname)
                    ? 'PUT'
                    : type_includes(DELETE, pathname)
                    ? 'DELETE'
                    : 'GET'
            ),
        [pathname]
    );

    const fetchHandler: fetchHandlerType = React.useCallback(
        async (opt, message) => {
            toggleLoading(true);
            const url =
                opt?.q && opt.ext
                    ? pathname + `/${opt.ext})?${opt.q}`
                    : opt?.ext
                    ? pathname + `/${opt.ext})`
                    : opt?.q
                    ? pathname + `?${opt.q}`
                    : pathname;
            try {
                const response = await axios({
                    method: method,
                    baseURL: routeConfig.baseServiceUrl,
                    url: url,
                    ...opt
                });
                console_log(`[${method}]: ${url}\n`, response);
                const data = response.data as IDataResponse;
                data.result === 'error'
                    ? alert.error(data.frontEndMessage)
                    : message &&
                      alert.success(
                          typeof message === 'boolean'
                              ? data.frontEndMessage
                              : message
                      );
                return data;
            } catch (err) {
                console_log(
                    `[${method}] `,
                    routeConfig.baseServiceUrl,
                    url,
                    ': CATCH ERROR\n',
                    err
                );
                alert.error('Catch Error');
                return catchResponse(err);
            } finally {
                toggleLoading(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [toggleLoading, pathname, method]
    );
    return [fetchHandler, loading];
}
