export const adminRoutes = [
    '/admin',
    '/admin/blogs',
    '/admin/contributors',
    '/logoff'
] as const;
export const publicRoutes = [
    '/',
    '/blogs',
    '/donate',
    '/login',
    '/why-we-walk'
] as const;

export type routeTypes =
    | typeof adminRoutes[number]
    | typeof publicRoutes[number];

export interface INavigate {
    path: routeTypes;
    ext?: string | undefined;
}

export type WithLink<T = {}> = T & {
    to?: INavigate;
    href?: string | undefined;
    blank?: boolean;
    target?: '_self' | '_blank' | '_parent' | '_top' | (string & {});
};

export default routeTypes;
