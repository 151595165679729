import React from 'react';
import { useHistory } from 'react-router';
import routeTypes from '../config/routes.config';

export default function useNavigate() {
    const history = useHistory();
    const [path, setPath] = React.useState<unknown>(history.location.pathname);

    React.useEffect(
        () => setPath(history.location.pathname),
        [history.location.pathname]
    );

    const navigate = React.useCallback(
        (path: routeTypes, ext?: string) =>
            ext ? history.push(path + ext) : history.push(path),
        [history]
    );
    const navigateOnClick = React.useCallback(
        (
                { path, ext }: { path: routeTypes; ext?: string },
                onClick?:
                    | React.MouseEventHandler<
                          HTMLButtonElement | HTMLDivElement
                      >
                    | undefined
            ) =>
            (
                e: React.MouseEvent<
                    HTMLButtonElement | HTMLDivElement,
                    MouseEvent
                >
            ) => {
                ext ? history.push(path + ext) : history.push(path);
                onClick && onClick(e);
            },
        [history]
    );
    return {
        navigate,
        navigateOnClick,
        path: path as routeTypes,
        hash: history.location.hash,
        ...history
    };
}
