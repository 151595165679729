import React from 'react';
import Divider, { DividerProps } from '@mui/material/Divider';
import Grid, { GridSize } from '@mui/material/Grid';

export interface GridDividerProps extends DividerProps {
    lg?: boolean | GridSize | undefined;
    md?: boolean | GridSize | undefined;
    sm?: boolean | GridSize | undefined;
    xs?: boolean | GridSize | undefined;
}
function GridDivider({
    xs,
    sm,
    md,
    lg,
    ...dividerProps
}: GridDividerProps): React.ReactElement {
    return (
        <Grid item xs={xs ? xs : 12} sm={sm} md={md} lg={lg}>
            <Divider variant='fullWidth' {...dividerProps} />
        </Grid>
    );
}

export default React.memo(GridDivider);
