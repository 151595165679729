import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import SettingsIcon from '@mui/icons-material/Settings';
import useConfig from '../../context/useConfig';
import { OptimisedToolbarProps } from './OptimisedTable.types';
import { Action } from '..';

export const OptimisedToolbar: React.FC<OptimisedToolbarProps> = React.memo(
    ({
        title,
        numOfItems,
        searchOptions,
        searchOptionHandler,
        searchHandler,
        Settings,
        Actions
    }) => {
        const { tableSettings, dispatchConfig } = useConfig();
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
            null
        );
        const open = Boolean(anchorEl);
        const openSettingsHandler = React.useCallback(
            (event: React.MouseEvent<HTMLElement>) =>
                setAnchorEl(event.currentTarget),
            []
        );
        const closeSettingsHandler = React.useCallback(
            () => setAnchorEl(null),
            []
        );
        const toggleDensity = React.useCallback(
            () => dispatchConfig({ type: 'TOGGLE_TABLE_SETTINGS_DENSITY' }),
            [dispatchConfig]
        );
        const toggleBadge = React.useCallback(
            () => dispatchConfig({ type: 'TOGGLE_TABLE_SETTINGS_BADGE' }),
            [dispatchConfig]
        );
        const togglePagination = React.useCallback(
            () => dispatchConfig({ type: 'TOGGLE_TABLE_SETTINGS_PAGINATION' }),
            [dispatchConfig]
        );
        const toggleSearch = React.useCallback(
            () => dispatchConfig({ type: 'TOGGLE_TABLE_SETTINGS_SEARCH' }),
            [dispatchConfig]
        );
        return (
            <Stack
                direction='row'
                justifyContent='space-between'
                p={1}
                sx={{ background: '#e0e0e0' }}
            >
                {title ? (
                    tableSettings.disableBadge ? (
                        <Typography
                            variant='h3'
                            component='h3'
                            fontWeight={500}
                        >
                            {title}
                        </Typography>
                    ) : (
                        <Badge
                            badgeContent={numOfItems}
                            color='primary'
                            max={999}
                        >
                            <Typography
                                variant='h3'
                                component='h3'
                                fontWeight={500}
                            >
                                {title}
                            </Typography>
                        </Badge>
                    )
                ) : (
                    <Container />
                )}
                <Stack direction='row'>
                    {!tableSettings.disableSearch && (
                        <Autocomplete
                            id='search-clients'
                            options={searchOptions ? searchOptions : []}
                            onChange={searchOptionHandler}
                            size='small'
                            sx={{ width: '10rem' }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label='Search'
                                    name='filter'
                                    onChange={searchHandler}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            )}
                        />
                    )}
                    {Actions}
                    <Action
                        av='IconButton'
                        label='table settings'
                        Icon={<SettingsIcon />}
                        onClick={openSettingsHandler}
                        sx={{ ml: 1 }}
                    />
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={closeSettingsHandler}
                        onClick={closeSettingsHandler}
                        transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top'
                        }}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom'
                        }}
                    >
                        <MenuItem onClick={toggleDensity}>
                            {tableSettings.dense
                                ? 'Comfortable View'
                                : 'Dense View'}
                        </MenuItem>
                        <MenuItem onClick={toggleBadge}>
                            {tableSettings.disableBadge
                                ? 'Enable Badge'
                                : 'Disable Badge'}
                        </MenuItem>
                        <MenuItem onClick={togglePagination}>
                            {tableSettings.disablePagination
                                ? 'Enable Pagination'
                                : 'Disable Pagination'}
                        </MenuItem>
                        <MenuItem onClick={toggleSearch}>
                            {tableSettings.disableSearch
                                ? 'Enable Search'
                                : 'Disable Search'}
                        </MenuItem>
                        {Settings}
                    </Menu>
                </Stack>
            </Stack>
        );
    }
);
