import React from 'react';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinkIcon from '@mui/icons-material/Link';
import routeTypes from '../../config/routes.config';
interface IItem {
    label?: string;
    to?: routeTypes;
    href?: string;
    value: string;
}

interface FooterColumnProps {
    title: string;
    items: IItem[];
}

function FooterColumn({ title, items }: FooterColumnProps): React.ReactElement {
    return (
        <Stack spacing={0.5}>
            <Typography variant='subtitle1' fontWeight={500}>
                {title}
            </Typography>
            {items.map(item => (
                <Stack
                    direction='row'
                    alignItems='center'
                    key={item.value}
                    spacing={0.5}
                >
                    <Typography
                        component={item.to ? Link : item.href ? 'a' : 'span'}
                        to={item.to}
                        href={item.href}
                        target={item.href && '_blank'}
                        variant='body2'
                    >
                        {item.label && item.label + ': '}
                        {item.value}
                    </Typography>

                    {item.href && (
                        <a
                            href={item.href}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <LinkIcon fontSize='small' />
                        </a>
                    )}
                </Stack>
            ))}
        </Stack>
    );
}
export default React.memo(FooterColumn);
