import React from 'react';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import MuiCard, { CardProps as MuiCardProps } from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Stack from '@mui/material/Stack';
import { BoxProps } from '@mui/material/Box';
import { StackProps } from '@mui/material/Stack';
import useNavigate from '../../../hooks/useNavigate';
import { WithActionProps } from '.';
import { WithLink } from '../../../config/routes.config';

export type WithCardProps<T = {}> = WithActionProps<
    T &
        MuiCardProps & {
            ActionAreaProps?: MuiButtonProps;
            StackProps?: StackProps;
            CardProps?: MuiCardProps;
            BoxProps?: BoxProps;
        }
>;

function ActionCard({
    href,
    target,
    ...props
}: WithLink<WithCardProps>): React.ReactElement {
    const { navigateOnClick } = useNavigate();
    return (
        <MuiCard
            {...props.CardProps}
            sx={props.sx}
            onClick={
                props.to
                    ? navigateOnClick(props.to, props.onClick)
                    : props.onClick
            }
        >
            {href ? (
                <a href={href} target={target} rel='noopener noreferrer'>
                    <CardActionArea {...props.ActionAreaProps}>
                        <Stack {...props.StackProps}>{props.children}</Stack>
                    </CardActionArea>
                </a>
            ) : (
                <CardActionArea {...props.ActionAreaProps}>
                    <Stack {...props.StackProps}>{props.children}</Stack>
                </CardActionArea>
            )}
        </MuiCard>
    );
}

export default React.memo(ActionCard);
