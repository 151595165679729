import React from 'react';
import ActionCard, { WithCardProps } from './Action.Card';
import ActionImage, { WithImageProps } from './Action.Image';
import ActionButton, { WithButtonProps } from './Action.Button';
import ActionIconButton, { WithIconButtonProps } from './Action.IconButton';
import Grid, { GridProps } from '@mui/material/Grid';
import { CircularProgressProps } from '@mui/material/CircularProgress';
import { WithLink } from '../../../config/routes.config';

export type WithActionProps<T = {}> = WithLink<
    WithGrid<
        T & {
            component?: React.ElementType<any>;
            label?: React.ReactNode;
            GridProps?: GridProps;
            loading?: boolean;
            loadingColor?:
                | 'inherit'
                | 'success'
                | 'info'
                | 'warning'
                | 'error'
                | 'primary'
                | 'secondary'
                | undefined;
            disabled?: boolean;
            CircularProgressProps?: CircularProgressProps;
        }
    >
>;

type options = { GridProps?: GridProps } & (
    | WithButtonProps<{ av: 'Button' }>
    | WithIconButtonProps<{ av: 'IconButton' }>
    | WithCardProps<{ av: 'Card' }>
    | WithImageProps<{ av: 'Image' }>
);
function Action(props: options) {
    return props.grid ? (
        <Grid
            item
            xs={props.xs ? props.xs : 12}
            sm={props.sm}
            md={props.md}
            lg={props.lg}
            {...props.GridProps}
        >
            <ActionItem {...props} />
        </Grid>
    ) : (
        <ActionItem {...props} />
    );
}
function ActionItem(props: options) {
    return props.av === 'Card' ? (
        <ActionCard {...props} />
    ) : props.av === 'Button' ? (
        <ActionButton {...props} />
    ) : props.av === 'IconButton' ? (
        <ActionIconButton {...props} />
    ) : props.av === 'Image' ? (
        <ActionImage {...props} />
    ) : null;
}

export default React.memo(Action);
export type {
    WithButtonProps,
    WithIconButtonProps,
    WithCardProps,
    WithImageProps
};
export { ActionButton, ActionIconButton, ActionCard, ActionImage };
