import React from 'react';

interface countOptionProps {
    changeBy?: number;
    min?: number;
    max?: number;
}

export default function useCount(
    initialState: number = 0,
    options: countOptionProps | undefined = undefined
): [
    count: number,
    setCountHandler: (
        operation: 'inc' | 'dec' | 'reset' | 'set'
    ) => (value?: number | any) => void
] {
    const [count, setCount] = React.useState<number>(
        initialState ? initialState : 0
    );
    const {
        changeBy = 1,
        min,
        max
    } = options ? options : { changeBy: 1, min: undefined, max: undefined };

    const setCountHandler = React.useCallback(
        (operation: 'inc' | 'dec' | 'reset' | 'set') =>
            (value?: number | any) => {
                setCount(prevCount => {
                    switch (operation) {
                        case 'reset':
                            return initialState;
                        case 'inc':
                            return typeof max === 'number' &&
                                prevCount + changeBy > max
                                ? prevCount
                                : prevCount + changeBy;
                        case 'dec':
                            return typeof min === 'number' &&
                                prevCount - changeBy < min
                                ? prevCount
                                : prevCount - changeBy;
                        case 'set':
                            return typeof value !== 'number' ||
                                (max && value > max) ||
                                (min && value < min)
                                ? prevCount
                                : value;
                        default:
                            return prevCount;
                    }
                });
            },
        [changeBy, initialState, max, min]
    );

    return [count, setCountHandler];
}
