import React from 'react';
import { InputEventProps } from '../components';

export default function useForm<T>(
    initialState: T
): [
    T,
    (key: keyof T, checked?: boolean) => (e: InputEventProps) => void,
    () => void
] {
    const [formState, setFormState] = React.useState<T>(initialState);

    const onChange = React.useCallback(
        (key: keyof T, checked?: boolean) => (e: InputEventProps) => {
            setFormState(prevFormState => ({
                ...prevFormState,
                [key]: checked ? e.target.checked : e.target.value
            }));
        },
        []
    );

    const reset = React.useCallback(
        () => setFormState(initialState),
        [initialState]
    );

    return [formState, onChange, reset];
}
