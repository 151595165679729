import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import useAuth from '../context/useAuth';

function LogoffPage(): React.ReactElement {
    const { logout } = useAuth();

    React.useEffect(() => {
        logout();
    }, [logout]);

    return (
        <Stack
            height='100vh'
            width='100vw'
            alignItems='center'
            justifyContent='center'
        >
            <CircularProgress size={36} />
        </Stack>
    );
}

export default React.memo(LogoffPage);
