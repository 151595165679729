import { Route, Redirect, RouteProps } from 'react-router-dom';
import useAuth from '../context/useAuth';
import NotFoundPage from '../pages/NotFound.Page';

export function AdminRoute({
    children,
    ...rest
}: RouteProps): React.ReactElement {
    const { RoleName } = useAuth();
    return (
        <Route
            {...rest}
            render={({ location }) =>
                RoleName === 'ADMIN' || RoleName === 'SYSADMIN' ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

export function NotFoundRoute(): React.ReactElement {
    return <Route component={NotFoundPage} />;
}
