import React from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { autoPlay } from 'react-swipeable-views-utils';
import useCount from '../../hooks/useCount';
import { routeConfig } from '../../config';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export interface IBlog {
    id: string;
    date_posted: Date;
    description: string;
    image_data: 'Array<string>';
    title: string;
}

function Blog(blog: IBlog): React.ReactElement {
    const location = useLocation();
    const theme = useTheme();
    const [activeStep, setStep] = useCount(0);
    const [page, setPage] = React.useState(false);

    React.useEffect(
        () => setPage(location.pathname === '/blogs'),
        [location.pathname]
    );

    return (
        <Card
            aria-label={`${blog.title} blog`}
            sx={{
                width: '100%',
                maxWidth: page ? '28rem' : '25rem',
                bgcolor: 'grey',
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'
                py={page ? 1 : 0.5}
                px={page ? 2 : 1}
            >
                <Typography variant='subtitle1' fontWeight={600}>
                    {blog.title}
                </Typography>
            </Stack>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={setStep('set')}
                interval={Math.floor(Math.random() * (8000 - 4000 + 1)) + 4000}
                enableMouseEvents
            >
                {JSON.parse(blog.image_data).map(
                    (step: string, index: number) => (
                        <div key={step}>
                            {Math.abs(activeStep - index) <= 2 ? (
                                <Box
                                    component='img'
                                    width='100%'
                                    maxHeight={page ? '18rem' : '15rem'}
                                    height={300}
                                    display='block'
                                    overflow='hidden'
                                    src={
                                        routeConfig.baseServiceUrl + '/' + step
                                    }
                                    alt={step}
                                    sx={{
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                        cursor: 'grab',
                                        ':active': { cursor: 'grabbing' }
                                    }}
                                    draggable={false}
                                />
                            ) : null}
                        </div>
                    )
                )}
            </AutoPlaySwipeableViews>
            <Typography variant='body2' p={page ? 2 : 1} pb={page ? 1 : 0.5}>
                {blog.description}
            </Typography>
            <Stack
                component='div'
                alignItems='flex-end'
                alignSelf='flex-end'
                justifyContent='flex-end'
                pr={page ? 2 : 1}
                flex={1}
                pb={page ? 1 : 0.5}
            >
                <Typography variant='subtitle2'>
                    {moment(blog.date_posted).format('HH:mm')}
                </Typography>
                <Typography variant='subtitle2'>
                    {moment(blog.date_posted).format('D MMM')}
                </Typography>
            </Stack>
        </Card>
    );
}

export default React.memo(Blog);
