import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Plant from '../../assets/gifs/Plant.gif';
import useConfig from '../../context/useConfig';
import { Action, Progress, SponsorsContribute } from '..';

function Sponsors(): React.ReactElement {
    const { dispatchConfig } = useConfig();
    return (
        <Stack my={7} alignItems='center' width='100%'>
            <Typography
                variant='h2'
                component='h2'
                fontWeight={500}
                textAlign='center'
            >
                Kilometers Sponsored
            </Typography>
            <Box
                component='img'
                src={Plant}
                width='100%'
                maxWidth='40rem'
                alignSelf='center'
                m='3rem auto'
                my={3}
            />
            <Progress />
            <SponsorsContribute />
            <Action
                av='Button'
                label='Donate'
                to={{ path: '/donate' }}
                onClick={() =>
                    dispatchConfig({ type: 'SET_DONATE_OPTION', option: 1 })
                }
                disableFullWidth
                sx={{ mt: 4 }}
            />
        </Stack>
    );
}

export default React.memo(Sponsors);
