import React from 'react';
import Typography from '@mui/material/Typography';
import { useFetch, useForm } from '../../hooks';
import { Form, Payfast } from '..';

function DonateMoney(): React.ReactElement {
    const [post, loading] = useFetch('/contact/donate-money');
    const [donateInfo, onChange] = useForm({
        FullName: 'anonymous',
        Email: '',
        Message: 'any',
        Amount: 0
    });
    const payfastRef = React.useRef<HTMLInputElement>(null);
    const submit = React.useCallback(async () => {
        const response = await post(
            { data: donateInfo },
            'Please complete payment in payfast'
        );
        response.result === 'success' && payfastRef.current?.click();
    }, [donateInfo, post]);

    return (
        <>
            <Form
                onFormSubmit={submit}
                loading={loading}
                label='Donate'
                Fields={[
                    {
                        label: 'Name',
                        onChange: onChange('FullName'),
                        optional: true,
                        endAdornment: (
                            <Typography variant='caption' component='legend'>
                                optional
                            </Typography>
                        )
                    },
                    {
                        label: 'Email',
                        onChange: onChange('Email'),
                        optional: true,
                        endAdornment: (
                            <Typography variant='caption' component='legend'>
                                optional
                            </Typography>
                        )
                    },
                    {
                        label: 'Purpose',
                        onChange: onChange('Message'),
                        optional: true,
                        endAdornment: (
                            <Typography variant='caption' component='legend'>
                                optional
                            </Typography>
                        )
                    },
                    {
                        label: 'Amount',
                        onChange: onChange('Amount'),
                        startAdornment: (
                            <Typography component='legend'>R</Typography>
                        )
                    }
                ]}
            />
            <Payfast
                price={donateInfo.Amount}
                name='Cape2Care Donation'
                path='/'
                payfastRef={payfastRef}
            />
        </>
    );
}

export default React.memo(DonateMoney);
