import { icons } from '../components';

export const NeedsList = [
    'Transport (12-seater kombi)',
    'Trailer',
    'Petrol / Diesel',
    'Driver',
    'Medical person',
    'First Aid Kit',
    'Overnight Facilities',
    'Food / daily meals and snacks',
    'Water',
    'Supplements / Vitamins / Energy drinks',
    'Footwear',
    'Sportswear (Clothing, sports bras, etc.)',
    'Sunscreen',
    'Strapping',
    'Backpacks with water dispensers',
    'Power banks',
    'R3500 per km sponsored'
];

type WishItemProps = {
    icon: keyof typeof icons;
    needs: string[];
};

export const WhishList: { [key: string]: WishItemProps } = {
    Daily_needs: {
        icon: 'DialyNeeds',
        needs: [
            'Non-perishable food (e.g. Canned food, long-life milk, etc.)',
            'Meat (e.g. Mince, sausage, etc.)',
            'Fresh fruits and vegetables',
            'Frozen products (e.g. Stork margarine, mixed vegetables, etc.)',
            'Cleaning products (e.g. Dishwashing liquid, handy andy, etc.)',
            'Toiletries (e.g. Shampoo and Conditioner, bodywash/ soap, etc.)',
            'Other (e.g. Birthday presents, clothing (new/second hand), etc.)'
        ]
    },
    Financial_needs: {
        icon: 'FinancialNeeds',
        needs: [
            'Salaries for the personnel',
            'Water and electricity',
            'Petrol',
            'School bus driver',
            'Therapy',
            'Doctor’s appointments',
            'Medicine',
            'Cleaning and gardening staff',
            'School clothing'
        ]
    },
    Premises_upgrading_needs: {
        icon: 'PremisesUpgradingNeeds',
        needs: [
            'Security',
            'Grass',
            'Irrigation',
            'Vegetable garden',
            'Paint',
            'Playground'
        ]
    },
    Long_term_wishes: {
        icon: 'LongTermWishes',
        needs: [
            'Therapy Centre',
            'Hostel (50 Children)',
            'Baby house',
            'Day care'
        ]
    }
};
