import { useState, useCallback } from 'react';
import { IContributor, ISponsor, SponsorsContext } from './Sponsors.Context';
import { useFetch } from '../../hooks';
import { dateFormats } from '../../config';
import moment from 'moment';

export default function SponsorsProvider({ children }: WithChildren) {
    const [fetchSponsors, sponsorsLoading] = useFetch('/sponsors');
    const [fetchContributors, contributorsLoading] = useFetch(
        '/sponsors/contributors'
    );
    const [sponsors, setSponsors] = useState<ISponsor[]>([]);
    const [contributors, setContributors] = useState<IContributor[]>([]);

    const getSponsors = useCallback(async () => {
        const response = await fetchSponsors();
        response.result === 'success' && setSponsors(response.data);
    }, [fetchSponsors]);

    const getContributors = useCallback(async () => {
        const response = await fetchContributors();
        response.result === 'success' &&
            setContributors(
                response.data.map((contributor: IContributor) => ({
                    ...contributor,
                    Link: contributor.Link ?? null,
                    DateAdded: moment(contributor.DateAdded).format(
                        dateFormats.normal
                    )
                }))
            );
    }, [fetchContributors]);

    const addSponsor = useCallback(
        sponsor => setSponsors(prevSponsors => [...prevSponsors, sponsor]),
        []
    );

    const addContributor = useCallback(
        contributor =>
            setContributors(prevContributors => [
                ...prevContributors,
                contributor
            ]),
        []
    );
    const editSponsor = useCallback(
        sponsor =>
            setSponsors(prevSponsors => [
                ...prevSponsors.filter(
                    prevSponsor => prevSponsor.Guid !== sponsor.Guid
                ),
                sponsor
            ]),
        []
    );

    const editContributor = useCallback(
        contributor =>
            setContributors(prevContributors => [
                ...prevContributors.filter(
                    prevContributor => prevContributor.Guid !== contributor.Guid
                ),
                contributor
            ]),
        []
    );

    return (
        <SponsorsContext.Provider
            value={{
                sponsors,
                contributors,
                sponsorsLoading,
                contributorsLoading,
                getSponsors,
                getContributors,
                addSponsor,
                addContributor,
                editSponsor,
                editContributor
            }}
        >
            {children}
        </SponsorsContext.Provider>
    );
}
