import MichelleProfile from '../assets/images/profiles/Michelle.jpg';
import MarleenProfile from '../assets/images/profiles/Marleen.jpg';
import LouiseProfile from '../assets/images/profiles/Louise.jpg';
import MagteldProfile from '../assets/images/profiles/Magteld.jpg';
import SoriaProfile from '../assets/images/profiles/Soria.jpg';
import ArinaProfile from '../assets/images/profiles/Arina.jpg';
import MeaghanProfile from '../assets/images/profiles/Meaghan.jpg';
import MarisiaProfile from '../assets/images/profiles/Marisia.jpeg';

export interface IWhyWeWalk {
    message: string;
    profile: string;
}

export const WhyWeWalkConstant: { [key: string]: IWhyWeWalk } = {
    Michelle_van_Biljon: {
        message: `I walk for our children who have been failed, failed by their circumstances, failed by their adult figures, failed by their environment, failed by false promises, unaware of how empowered they actually are, and the ability they have to change their circumstances. I walk for our Children with potential who just need the ONE opportunity to rise above their circumstances and change the failure into freedom.`,
        profile: MichelleProfile
    },
    Marleen_van_der_Walt: {
        message: `My whole life I had this feeling that I wanted to do something significant, to make an impact - not only in my life, but in the lives of others. I want to walk for children - for their dreams, for their furture, for children that don't have a voice. I also see this opportunity as a Spirtual journey, to grow in my faith and to come closer to God and reconnecting my strength in my faith`,
        profile: MarleenProfile
    },
    Louise_Mostert: {
        message: `Firstly, I'm taking on this journey because the purpose of this project is aligned with my passion. Secondly, because Me and Marleen decided two years ago that we want to take on this journey to walk to Cape Town. Thirdly, it's a journey between me and my Father, my Savior, my Everything. It's my sabbatical. Lastly, my sister used to be the mountain hiker and due to her illness breaking down her skeleton, i also dedicate this journey of mine, to her.`,
        profile: LouiseProfile
    },
    Magteld_Arnold: {
        message: `What a privilege to be part of this fundraising project launched by # CAPE2CARE for 2022. A dream is shared with you to uplift and enhance your immediate community and specifically Dynamic Women for Children. It is presented in a “see it, say it, grab it” format, and gives an opportunity to make a contribution. It is a project that challenges body, soul and spirit to show courage. It is daring on various levels in order to reach a dream meant for the upliftment for someone else. As a Life Coach, I am daily in the position of reminding clients of their potential, moving them out of their comfort zone and encouraging them to bridge their “Sap strata” (those levels of existence above which it is difficult to rise).  With this fundraising project, which involves walking from Pretoria to Cape Town for probably 21 days, I step into an area of someone who has not tasted tekkie triumph for a long time!  As part of a team, I make myself available to join them kilometer by kilometer and to contribute towards a dream of upliftment.`,
        profile: MagteldProfile
    },
    Soria_van_Wyngaardt: {
        message: `My name is Soria van Wyngaardt and I feel passionate about Cape2Care for the following reasons:
    1. To honour God for being faithful even in my worst chapter of my life.
    2. In remembrance of my son Lawrens (Lawi) who passed away at the age of 22, who had this dream to become a youth pastor and work with children who are traumatized and without hope for the future.
    3. It’s part of my grief journey and Spiritual growth.
    4. I hope to make a difference in a child’s life and give them hope by being part of this fundraiser.
    
    My scriptures are from psalm 23 and specifically verse 4 & 6!`,
        profile: SoriaProfile
    },
    Arina_Erasmus: {
        message: `I have always had a soft spot in my heart for children with difficult circumstances and needs.
    I want to reach out to children in need in my own humble way, and by joining the Cape2Care initiative I hope to make a difference in their lives, as well as to spread the message of the importance of investing in the development of a child. 
    
    In Luke 18 and Mark 10 Jesus said that the little children should come to Him for He is love and wants to bless them!
    How will children ever be able to experience and feel His love if no one reaches out to them and makes a tangible difference in their circumstances?
    
    Dynamic Women for Children is an organisation that makes a big difference in the lives of children with difficult circumstances. They provide for their basic needs with care and love.
    
    That is why I want to be part of this fundraising so that more children can benefit from the goodness, love and care of this organisation.
    
    "And he took them up in his arms, put his hands upon them, and blessed them."
    Mark 10:16 KJV
    
    Arina Erasmus `,
        profile: ArinaProfile
    },
    Meaghan_Badenhorst: {
        message: `I am first and foremost walking for Dynamic women for Children. I believe that their intentions and
    hearts are pure and the difference they make daily in children’s lives is amazing. The journey to Cape
    Town is such a big honor to me because we are doing it for the children, to be able to give them an even
    better chance at life. I believe that charity work enables one to give something without taking
    something from yourself. I have a big passion for working with children and that is why I am going to
    take on this adventure with Dynamic Women for Children. My second reason is because we are an all-
    woman group of adventurers that have the same goal and mission, yet we are all taking our own
    spiritual path as well, to prove to ourselves that we can do this.`,
        profile: MeaghanProfile
    },
    Marisia_van_Wyk: {
        message: `I am passionate about children for two reasons: I want children to have opportunies all children should have growing up and children don’t always have control over their circumstances and environment so we need to be their voice, we need to stand up for them and we need to set the bar really high so they have no other way than to achieve soaring  heights. I’ve decided to embark on this journey in honour of each and every child that has been failed by a system, failed by parents and failed by circumstances beyond their control.`,
        profile: MarisiaProfile
    }
};

export type whyWeWalkType = typeof WhyWeWalkConstant;
