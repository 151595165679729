import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Backdrop from '@mui/material/Backdrop';

export interface LoadingProps {
    loading: boolean;
    color?: ColorOptions;
    backdrop?: boolean;
    relative?: boolean;
}

function Loading({
    loading,
    color,
    backdrop,
    relative,
    children
}: WithChildren<LoadingProps>): React.ReactElement {
    const [loadingState, setLoadingState] = React.useState<boolean>(loading);
    React.useEffect(() => setLoadingState(loading), [loading]);
    return (
        <>
            {loadingState ? (
                backdrop ? (
                    <Backdrop
                        sx={{
                            color: '#fff',
                            zIndex: theme => theme.zIndex.drawer + 1
                        }}
                        open={loadingState}
                    >
                        <LinearProgress
                            color={color ?? 'primary'}
                            sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0
                            }}
                        />
                    </Backdrop>
                ) : (
                    <LinearProgress
                        color={color ?? 'primary'}
                        sx={{
                            position: relative ? 'absolute' : 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: 100
                        }}
                    />
                )
            ) : (
                children
            )}
        </>
    );
}

export default React.memo(Loading);
