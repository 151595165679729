import React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ArrowBackIosSharp from '@mui/icons-material/ArrowBackIosSharp';
import { Icon } from '@mui/material';
import useNavigate from '../../hooks/useNavigate';
import {
    Action,
    ActionButton,
    ActionIconButton,
    WithButtonProps,
    WithIconButtonProps,
    NavbarProps
} from '..';

interface NavbarLinksProps extends NavbarProps {
    LinkProps?: WithButtonProps;
    ActionProps?: WithIconButtonProps;
    Actions?: WithIconButtonProps[];
    Links?: WithButtonProps[];
}

function NavbarLinks({
    bg,
    mw,
    disableGoBack,
    LinkProps,
    ActionProps,
    Links,
    Actions,
    ActionStackProps,
    LinksStackProps,
    BoxProps,
    ...stackProps
}: NavbarLinksProps): React.ReactElement {
    const { path, goBack } = useNavigate();
    return (
        <Box
            component='nav'
            position='fixed'
            top={0}
            left={0}
            right={0}
            p={2}
            zIndex={10}
            bgcolor={bg ? bg : ''}
            {...BoxProps}
        >
            <Container maxWidth={mw ? mw : 'xl'} disableGutters>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    {...stackProps}
                >
                    {!disableGoBack && (
                        <Action
                            av='IconButton'
                            label={path !== '/' && 'go back'}
                            Icon={
                                path !== '/' ? <ArrowBackIosSharp /> : <Icon />
                            }
                            onClick={path !== '/' ? goBack : undefined}
                            disabled={path === '/'}
                        />
                    )}
                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing={{ xs: 0, sm: 1.5 }}
                        {...LinksStackProps}
                    >
                        {Links?.map(link => (
                            <ActionButton {...LinkProps} {...link} />
                        ))}
                    </Stack>
                    <Stack
                        direction='row'
                        alignItems='center'
                        spacing={{ xs: 0, sm: 1 }}
                        {...ActionStackProps}
                    >
                        {Actions?.map(action => (
                            <ActionIconButton
                                {...ActionProps}
                                {...action}
                                disabled={action.to?.path === path}
                            />
                        ))}
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
}
export default React.memo(NavbarLinks);
