import React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { IWhyWeWalk } from '../../constants';

interface WhyWeWalkProps extends IWhyWeWalk {
    name: string;
    invert?: boolean;
}

function WhyWeWalk({
    name,
    invert,
    ...whyWeWalk
}: WhyWeWalkProps): React.ReactElement {
    return (
        <Stack
            alignItems='center'
            direction={{
                xs: 'column-reverse',
                md: invert ? 'row-reverse' : 'row'
            }}
            spacing={5}
        >
            <Typography flex={1}>{whyWeWalk.message}</Typography>
            <Stack component='div' flex={1} alignItems='center' spacing={2}>
                <Box
                    component='img'
                    src={whyWeWalk.profile}
                    alt={whyWeWalk.profile}
                    borderRadius='50%'
                    minWidth='17rem'
                    minHeight='17rem'
                    maxWidth='17rem'
                    maxHeight='17rem'
                    sx={{ objectFit: 'cover', objectPosition: 'center' }}
                />
                <Typography variant='subtitle1'>{name}</Typography>
            </Stack>
        </Stack>
    );
}
export default React.memo(WhyWeWalk);
