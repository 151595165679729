import React from 'react';
import Paper from '@mui/material/Paper';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableData, { WithTableDataProps } from './Table.Data';
import TableHead from './Table.Head';
import Toolbar from './Toolbar';
import { useBoolean } from '../../hooks';
import { Loading } from '..';

interface TableProps<GenericData> extends WithTableDataProps<GenericData> {
    sortByDefault: keyof GenericData;
    loading: boolean;
    title?: string;
    headColor?: string;
    bodyColor?: string;
    initialRow?: GenericData;
}

function Table<FunctionGenericData>(props: TableProps<FunctionGenericData>) {
    const { actionsPosition = 'start' } = props;
    const [sortBy, setSortBy] = React.useState<keyof FunctionGenericData>(
        props.sortByDefault
    );
    const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>(
        'asc'
    );
    const [add, toggleAdd] = useBoolean();

    const descendingComparator = React.useCallback(
        (a: FunctionGenericData, b: FunctionGenericData) =>
            b[sortBy] < a[sortBy] ? -1 : b[sortBy] > a[sortBy] ? 1 : 0,
        [sortBy]
    );
    const getComparator = React.useCallback(
        (a: FunctionGenericData, b: FunctionGenericData) => {
            return sortDirection === 'desc'
                ? descendingComparator(a, b)
                : -descendingComparator(a, b);
        },
        [descendingComparator, sortDirection]
    );

    const onUpdateSort = React.useCallback(
        newSortBy => () => {
            console.log('New Sort: ', newSortBy);
            console.log('Sort: ', sortBy);

            setSortDirection(
                sortBy === newSortBy && sortDirection === 'asc' ? 'desc' : 'asc'
            );
            setSortBy(newSortBy);
        },
        [sortBy, sortDirection]
    );

    return (
        <TableContainer
            sx={{ mt: 6, bgcolor: props.headColor ?? '#eee' }}
            component={Paper}
        >
            <Toolbar title={props.title} add={add} toggleAdd={toggleAdd} />
            <MuiTable>
                <TableHead
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                    onUpdateSort={onUpdateSort}
                    {...props}
                    actionsPosition={actionsPosition}
                />
                <Loading loading={props.loading}>
                    <TableBody sx={{ bgcolor: props.bodyColor ?? '#e9e9e9' }}>
                        {props.data.sort(getComparator).map((row, index) => (
                            <TableData
                                key={index}
                                row={row}
                                {...props}
                                actionsPosition={actionsPosition}
                            />
                        ))}
                        {props.initialRow && add && (
                            <TableData
                                add
                                row={props.initialRow}
                                toggleAdd={toggleAdd}
                                {...props}
                                actionsPosition={actionsPosition}
                            />
                        )}
                    </TableBody>
                </Loading>
            </MuiTable>
        </TableContainer>
    );
}

export default React.memo(Table) as typeof Table;
