import React from 'react';
import Form from '../form';
import { useFetch, useForm } from '../../hooks';

function AddSponsor(): React.ReactElement {
    const [post, loading] = useFetch('/sponsors/add');
    const [details, onChange, reset] = useForm({
        Logo: '',
        Link: ''
    });

    const addSponsor = React.useCallback(
        async () => await post({ data: details }, 'Added Sponsor'),
        [details, post]
    );

    return (
        <Form
            onFormSubmit={addSponsor}
            reset={reset}
            label='Add Sponsor'
            Fields={[
                { name: 'Logo', onChange: onChange('Logo') },
                { name: 'Link', onChange: onChange('Link') }
            ]}
            loading={loading}
        />
    );
}
export default React.memo(AddSponsor);
