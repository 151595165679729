import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Action, Dialog, WithButtonProps } from '..';
import useBoolean from '../../hooks/useBoolean';
//TODO: Add Dialog Component
function SponsorButton(props: WithButtonProps): React.ReactElement {
    const [open, toggle] = useBoolean();
    return (
        <>
            <Action
                av='Button'
                onClick={toggle}
                sx={{ mt: 4 }}
                disableFullWidth
                {...props}
            />

            <Dialog
                open={open}
                onClose={() => toggle(false)}
                label='How to become a sponsor'
                // options={{
                //     open: alert.options.open,
                //     message: 'How to become a sponsor?'
                // }}
            >
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Typography>Read:</Typography>
                        <Action
                            av='Button'
                            label='Proposal'
                            size='large'
                            href='/pdfs/proposal.pdf'
                            target='_blank'
                        />
                    </Stack>
                    <Stack spacing={1}>
                        <Typography>Submit:</Typography>
                        <Action
                            av='Button'
                            label='Sponsorship form'
                            href='/pdfs/sponsorship.pdf'
                            target='_blank'
                            color='secondary'
                            size='large'
                        />
                    </Stack>
                </Stack>
            </Dialog>
        </>
    );
}
export default React.memo(SponsorButton);
