import React from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Navbar, WhyWeWalk } from '../components';
import { WhyWeWalkConstant } from '../constants';

function WhyWeWalkPage(): React.ReactElement {
    return (
        <Navbar sxContainer={{ pb: 6 }}>
            <Stack
                mt={5}
                spacing={8}
                divider={<Divider flexItem />}
                width='100%'
            >
                {Object.keys(WhyWeWalkConstant).map((key, index) => (
                    <WhyWeWalk
                        key={index}
                        name={key.replaceAll('_', ' ')}
                        invert={index % 2 === 0}
                        {...WhyWeWalkConstant[key]}
                    />
                ))}
            </Stack>
        </Navbar>
    );
}
export default React.memo(WhyWeWalkPage);
