import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Navigator } from './routing';
import { Providers } from './context';
import Palette from './config/Palette';

export default function App(): React.ReactElement {
    const muiTheme = createTheme({
        palette: Palette,
        typography: { fontFamily: 'Rubik' }
    });
    return (
        <ThemeProvider theme={muiTheme}>
            <Providers>
                <Navigator />
            </Providers>
        </ThemeProvider>
    );
}
