import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { WhishList } from '../../constants';
import { Action, LordIcon } from '..';
import { Palette } from '../../config';

function Wishlist(): React.ReactElement {
    return (
        <Stack alignItems='center' width='100%' my={7}>
            <Typography
                variant='h2'
                component='h2'
                fontWeight={500}
                textAlign='center'
                mb={3}
            >
                Our Charity Needs
            </Typography>
            <Grid container spacing={4} alignItems='stretch' width='100%'>
                {Object.keys(WhishList).map(key => (
                    <Grid item xs={12} sm={6} lg={3} mt={4}>
                        <Paper
                            key={key}
                            elevation={3}
                            sx={{
                                bgcolor: 'background.paper',
                                p: 2,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <LordIcon
                                icon={WhishList[key].icon}
                                delay={3000}
                                secondary={Palette.primary?.main}
                                style={{
                                    maxWidth: '8rem',
                                    alignSelf: 'center',
                                    marginTop: '-5rem'
                                }}
                            />
                            <Typography
                                variant='h4'
                                component='h4'
                                fontWeight={500}
                            >
                                {key.replaceAll('_', ' ')}
                            </Typography>
                            <Stack spacing={0.5} mt={1}>
                                {WhishList[key].needs.map(need => (
                                    <Stack direction='row' spacing={0.8}>
                                        <Typography variant='body2'>
                                            —
                                        </Typography>
                                        <Typography variant='body2'>
                                            {need}
                                        </Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <Action
                av='Button'
                label='Make a difference'
                sx={{ mt: 4 }}
                to={{ path: '/donate' }}
                disableFullWidth
            />
        </Stack>
    );
}
export default React.memo(Wishlist);
